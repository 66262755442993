import React, { FC, PropsWithChildren } from "react";
import { useUser } from "../../providers/UserProvider";

export const AdminOnly: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser();

  if (user?.role?.type !== "ADMIN") {
    return null;
  }

  return <>{children}</>;
};
