import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import qs from "qs";

export const useDotySearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo<Record<string, any>>(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  const setParam = useCallback(
    (key: string, value: any) => {
      const params = { ...searchParams, [key]: value };
      navigate({
        search: qs.stringify(params),
      });
    },
    [searchParams, navigate]
  );

  return {
    setParam,
    searchParams,
  };
};
