import clsx from "clsx";
import { Route, Routes } from "react-router-dom";
import { APP_ROUTES } from "../../pages";

type Props = {
  className: string;
};

const getRouteKey = (path: string | string[]) =>
  Array.isArray(path) ? path.join("-") : path;

export function AppRoutes({ className }: Props) {
  return (
    <div id="APP_ROUTES" className={clsx("app-routes", className)}>
      <Routes>
        {APP_ROUTES.map((route) => {
          return (
            <Route
              key={getRouteKey(route.path)}
              path={route.path as string}
              element={<route.component />}
            />
          );
        })}
      </Routes>
    </div>
  );
}
