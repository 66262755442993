import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { DotyCandidatesDto, SiteSettingsDto } from "../../../dtos";
import { useSiteSettings } from "../../../hooks/useSiteSettings";

type Props = {
  dotys: DotyCandidatesDto[];
};

const createJsonLD = (items: any[], siteSettings: SiteSettingsDto) => ({
  "@context": "https://schema.org",
  "@type": "WebPage",
  name: siteSettings.seoTitle || siteSettings.siteTitle,
  description:
    "Welcome to the 2024 Dilf of the Year awards. Below you will find a list of candidates for this year's coveted award. The winner will take home a brand new pair of white New Balance sneakers and bragging rights for all of 2025.",
  url: siteSettings.siteUrl,
  about: {
    "@type": "Event",
    name: "2024 Dilf of the Year",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    location: {
      "@type": "Place",
      name: "Online",
      url: siteSettings.siteUrl,
    },
    award: "New Balance Sneakers",
  },
  mainEntity: {
    "@type": "ItemList",
    itemListElement: items,
  },
});

const createDotyListElement = (
  doty: DotyCandidatesDto,
  siteSettings: SiteSettingsDto
) => {
  const image = doty.images.map((image) =>
    new URL(image.url, siteSettings.siteUrl).toString()
  );
  const { _id: identifier, fullName, description } = doty;

  return {
    "@type": "Person",
    name: fullName,
    identifier,
    description,
    image,
    potentialAction: {
      "@type": "VoteAction",
      target: new URL(`/doty/${identifier}`, siteSettings.siteUrl).toString(),
    },
  };
};

export const DotyListJsonLD: React.FC<Props> = ({ dotys }) => {
  const { siteSettings } = useSiteSettings();
  const itemList = useMemo(() => {
    return dotys.map((doty) => createDotyListElement(doty, siteSettings));
  }, [dotys, siteSettings]);

  const jsonLD = useMemo(
    () => createJsonLD(itemList, siteSettings),
    [itemList, siteSettings]
  );

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
    </Helmet>
  );
};
