import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PagesDto } from "../../../dtos";
import { useAppData } from "../../../providers/AppDataProvider";
import { API } from "../../../client/API";
import { getDtoMap } from "../../../utils/getDtoMap";

type RouteParams = {
  slug: string;
};

type PagesState = Record<string, PagesDto | undefined>;

export const usePagesProvider = () => {
  const {
    appData: { page },
  } = useAppData();
  const { slug: slugParam } = useParams<RouteParams>();
  const slug = slugParam || "home";
  const [pages, setPages] = useState<PagesState>(() => {
    if (page) {
      return { [page.slug]: page };
    }
    return {};
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const currentPage = useMemo<PagesDto | undefined>(() => {
    return pages[slug];
  }, [slug, pages]);

  const fetchPage = useCallback(() => {
    setIsLoading(true);
    return API.get(`/pages/${slug}`)
      .then((page) => {
        setPages((prev) => ({ ...prev, [slug]: page }));
        setError(null);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [slug]);

  const fetchAllPages = useCallback(() => {
    return API.get(`/pages`)
      .then((pages: PagesDto[]) => {
        const pagesMap = getDtoMap(pages, "slug");
        setPages((prev) => ({ ...pagesMap, ...prev }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchAllPages();
  }, []);

  useEffect(() => {
    // document.body.scrollTo({ left: 0, top: 10, behavior: "smooth" });
  }, [slug]);

  useEffect(() => {
    if (!currentPage) {
      fetchPage();
    }
  }, [slug, currentPage]);

  return {
    page: currentPage,
    isLoading,
    error,
    fetchPage,
    fetchAllPages,
  };
};
