import React, { useState } from "react";
import { Box, IconButton, OutlinedInput } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useCommentsContext } from "../CommentsList/CommentsProvider/CommentsProvider";
import { CommentsDto, ReplyDto } from "../../dtos";
import { useUser } from "../../providers/UserProvider";
import { CustomAvatar } from "../Avatar/CustomAvatar";
import styles from "./CommentsField.module.scss";

type Props = {
  parentComment?:
    | (CommentsDto & { ancestorIds?: string[] })
    | (ReplyDto & { ancestorIds?: string[] });
  afterSubmit?: () => void;
  autoFocus?: boolean;
  isMobile?: boolean;
};

export const CommentsField: React.FC<Props> = ({
  parentComment,
  afterSubmit,
  autoFocus,
  isMobile,
}) => {
  const { user } = useUser();
  const { addComment } = useCommentsContext();
  const [comment, setComment] = useState("");

  const handleSubmit = async () => {
    await addComment(
      comment,
      parentComment?._id || "",
      parentComment?.ancestorIds || []
    );
    setComment("");
    afterSubmit?.();
  };

  const isReply = !!parentComment?._id;

  const commentText = !!parentComment
    ? parentComment.user.fullName
    : "this comment";

  const placeholder = isReply
    ? `Reply to ${commentText}`
    : "Write a comment...";

  if (!user?._id) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" gap={1} my={!isReply ? 2 : 0}>
      <Box flex={1}>
        <OutlinedInput
          fullWidth
          multiline
          sx={{
            borderRadius: isMobile ? 6 : 8,
            py: 1,
            pl: 1.5,
            fontSize: "1rem",
          }}
          value={comment}
          placeholder={placeholder}
          autoFocus={isReply || autoFocus}
          startAdornment={
            <CustomAvatar
              sx={{
                width: isMobile ? 32 : 48,
                height: isMobile ? 32 : 48,
                fontSize: isMobile ? "1rem" : "1.25rem",
                mr: 1.5,
                mt: isMobile ? "1px" : 0,
                alignSelf: "flex-start",
              }}
              profilePicture={user.profilePicture}
              fullName={user.fullName}
            />
          }
          endAdornment={
            <IconButton
              color="primary"
              className={styles.submitButton}
              onClick={handleSubmit}
              sx={{
                alignSelf: "flex-end",
                position: "relative",
                top: isMobile ? undefined : -6,
                backgroundColor: "secondary.main",
                color: "#222",
              }}
              disabled={!comment.trim().length}
            >
              <ArrowUpwardIcon />
            </IconButton>
          }
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};
