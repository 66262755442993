import React, {
  createContext,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  PropsWithChildren,
  createRef,
} from "react";
import { usePageEditor } from "../PageStore/PageEditorProvider";
import { PageElementDto } from "../../../dtos";

type Props = {
  mainRef: RefObject<HTMLDivElement>;
  params: Record<string, string>;
  navigateHash: (hash: string) => void;
};

type Context = {
  element: PageElementDto | undefined;
} & Props;

const RouteContext = createContext<Context>({
  mainRef: createRef(),
  element: undefined,
  params: {},
  navigateHash: (hash: string) => {},
});

const EDIT_ELEMENT_EVT = "EDIT_ELEMENT";

export const dispatchEditElementEvt = (elementId: string) => {
  window.dispatchEvent(
    new CustomEvent(EDIT_ELEMENT_EVT, { detail: elementId })
  );
};

const createHistoryListeners = (navigateHash: Props["navigateHash"]) => {
  const handleEvt = (e: CustomEvent) => {
    navigateHash(`/element/${e.detail}`);
  };
  window.addEventListener(EDIT_ELEMENT_EVT, handleEvt as any, false);

  return () => {
    window.removeEventListener(EDIT_ELEMENT_EVT, handleEvt as any, false);
  };
};

export const RouteProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  mainRef,
  params,
  navigateHash,
}) => {
  const { elementId } = params;
  const { getElement } = usePageEditor()!;

  const element = useMemo(() => {
    return elementId ? getElement(elementId) : undefined;
  }, [elementId, getElement]);

  useEffect(() => {
    return createHistoryListeners(navigateHash);
  }, []);

  return (
    <RouteContext.Provider value={{ mainRef, element, params, navigateHash }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => useContext(RouteContext);
