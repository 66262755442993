import React, { useEffect } from "react";
import { CommentItem } from "./CommentItem/CommentItem";
import {
  CommentsContext,
  useCommentsProvider,
} from "./CommentsProvider/CommentsProvider";
import { ProtectedComponent } from "../ProtectedComponent/ProtectedComponent";
import { CommentsField } from "../CommentsField/CommentsField";
import { Box, Paper, Skeleton } from "@mui/material";

type Props = {
  referenceId: string;
  numberOfComments: number;
  isMobile?: boolean;
  isOutlined?: boolean;
  onCommentsCountChange?: (count: number) => void;
  fieldPortalEl?: HTMLDivElement;
};

export const CommentsList: React.FC<Props> = ({
  referenceId,
  isMobile,
  isOutlined,
  onCommentsCountChange,
  numberOfComments = 0,
}) => {
  const context = useCommentsProvider({
    referenceId,
    onModel: "DotyCandidates",
  });

  useEffect(() => {
    if (!context.isLoading) {
      onCommentsCountChange?.(context.flattenedComments.length);
    }
  }, [context.isLoading, context.flattenedComments.length]);

  const commentsJSX =
    context.flattenedComments.length > 0 ? (
      context.flattenedComments.map((comment: any) => {
        return (
          <CommentItem
            key={comment._id}
            item={comment}
            setComments={context.setComments}
            isMobile={isMobile}
            isOutlined={isOutlined}
          />
        );
      })
    ) : (
      <Box
        border="1px solid rgba(100,100,100,0.5)"
        borderRadius={4}
        textAlign="center"
        m={2}
        p={2}
        fontSize={15}
      >
        <b>No Comments.</b>
        <br />
        <small>
          <i>Start the conversation.</i>
        </small>
      </Box>
    );

  const renderJSX = () => {
    const shouldRenderLoader =
      context.isLoading ||
      (context.flattenedComments.length === 0 && numberOfComments > 0);

    if (shouldRenderLoader) {
      const loadingItems = Array.from(
        { length: numberOfComments },
        (_, index) => ({ key: index })
      );

      return loadingItems.map(({ key }) => {
        return (
          <Box key={key} gap={2} my={1} display="flex">
            <Skeleton
              variant="circular"
              width={isMobile || isOutlined ? 32 : 50}
              height={isMobile || isOutlined ? 32 : 50}
            />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={60} />
          </Box>
        );
      });
    }

    return commentsJSX;
  };

  return (
    <CommentsContext.Provider value={context}>
      {!isMobile && (
        <ProtectedComponent
          isLoginEnabled
          isRegisterEnabled
          loginMessage="You must be logged in to add a comment."
        >
          {isOutlined ? (
            <Box
              component={Paper}
              mt={2}
              variant="outlined"
              px={1}
              width="100%"
            >
              <CommentsField />
            </Box>
          ) : (
            <CommentsField />
          )}
        </ProtectedComponent>
      )}
      {isMobile ? (
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          gap={1}
          maxHeight="100%"
          sx={{ px: 1, pt: 1, pb: 2, overflowY: "auto", overflowX: "hidden" }}
        >
          {renderJSX()}
        </Box>
      ) : (
        renderJSX()
      )}
      {isMobile && (
        <Box borderTop="1px solid rgba(100, 100, 100, 0.5)" px={2}>
          <ProtectedComponent
            isLoginEnabled
            isRegisterEnabled
            loginMessage="You must be logged in to add a comment."
            sx={{
              fontSize: 14,
              border: 0,
              ".MuiButton-text": { fontSize: "inherit", py: 0 },
            }}
          >
            <CommentsField isMobile />
          </ProtectedComponent>
        </Box>
      )}
    </CommentsContext.Provider>
  );
};
