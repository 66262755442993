import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Components } from "@mui/material/styles/components";
import { PaletteOptions } from "@mui/material/styles/createPalette";

const palette: PaletteOptions = {
  primary: {
    main: "#1976d2",
    light: "#4791db",
    dark: "#115293",
    contrastText: "#fff",
  },
  secondary: {
    main: "#ad1457",
    light: "#c2185b",
    dark: "#880e4f",
    contrastText: "#fff",
  },
  error: {
    main: "#d32f2f",
    light: "#ef5350",
    dark: "#c62828",
  },
  warning: {
    main: "#ed6c02",
    light: "#ff9800",
    dark: "#e65100",
  },
  info: {
    main: "#0288d1",
    light: "#03a9f4",
    dark: "#01579b",
  },
  success: {
    main: "#2e7d32",
    light: "#4caf50",
    dark: "#1b5e20",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  background: {
    default: "#f8f9fa",
    paper: "#ffffff",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.6)",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
};

const typography: TypographyOptions = {
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
  ].join(","),
  fontSize: 16,
  h1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 600, // Slightly bolder for Montserrat
    lineHeight: 1.2,
    color: "#1a1a1a",
    letterSpacing: "-0.02em", // Tighter tracking for headings
  },
  h2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.3,
    color: "#1a1a1a",
    letterSpacing: "-0.02em",
  },
  h3: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#1a1a1a",
    letterSpacing: "-0.015em",
  },
  h4: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#1a1a1a",
    letterSpacing: "-0.015em",
  },
  h5: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#1a1a1a",
    letterSpacing: "-0.01em",
  },
  h6: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#1a1a1a",
    letterSpacing: "-0.01em",
  },
  body1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.5,
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "0.01em", // Slightly looser for body text
  },
  body2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "0.01em",
  },
  button: {
    fontFamily: "Montserrat, sans-serif",
    textTransform: "none",
    fontWeight: 500,
    letterSpacing: "0.02em", // Slightly looser for buttons
  },
  subtitle1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "0.015em",
  },
  subtitle2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "0.015em",
  },
};

const components: Components<Omit<Theme, "components">> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        // padding: "8px 16px",
        textTransform: "none",
        fontFamily: "Montserrat, sans-serif",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&.MuiButton-colorInherit": {
          backgroundColor: (palette.grey as any)[200], // Custom primary color
          "&:hover": {
            backgroundColor: (palette.grey as any)[400], // Lighter hover effect
          },
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        //  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        fontFamily: "Montserrat, sans-serif",
        "& .MuiOutlinedInput-root": {
          borderRadius: 8,
          "& fieldset": {
            borderColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        //  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily: "Montserrat, sans-serif",
      },
    },
  },
};

const themeOptions: ThemeOptions = {
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  // spacing: 12,
  components,
};

const darkPalette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#90caf9", // Lighter blue for dark theme
    light: "#b3e5fc",
    dark: "#5d99c6",
    contrastText: "#000",
  },
  secondary: {
    main: "#f48fb1", // Lighter rose for dark theme
    light: "#f6a5c0",
    dark: "#bf5f82",
    contrastText: "#000",
  },
  error: {
    main: "#f44336",
    light: "#e57373",
    dark: "#d32f2f",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb74d",
    dark: "#f57c00",
  },
  info: {
    main: "#29b6f6",
    light: "#4fc3f7",
    dark: "#0288d1",
  },
  success: {
    main: "#66bb6a",
    light: "#81c784",
    dark: "#388e3c",
  },
  grey: {
    50: "#fafafa",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
  background: {
    default: "#121212", // Material Design dark theme background
    paper: "#1e1e1e", // Slightly lighter than default for cards/surfaces
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
  },
};

const darkTypogarphy: TypographyOptions = {
  fontFamily: "Montserrat, sans-serif",
  h1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 600,
    lineHeight: 1.2,
    color: "#fff",
    letterSpacing: "-0.02em",
  },
  h2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.3,
    color: "#fff",
    letterSpacing: "-0.02em",
  },
  h3: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#fff",
    letterSpacing: "-0.015em",
  },
  h4: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#fff",
    letterSpacing: "-0.015em",
  },
  h5: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#fff",
    letterSpacing: "-0.01em",
  },
  h6: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: 1.4,
    color: "#fff",
    letterSpacing: "-0.01em",
  },
  body1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.01em",
  },
  body2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.01em",
  },
  button: {
    fontFamily: "Montserrat, sans-serif",
    textTransform: "none",
    fontWeight: 500,
    letterSpacing: "0.02em",
  },
  subtitle1: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "0.015em",
  },
  subtitle2: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: "0.015em",
  },
};

const darkComponents: Components<Omit<Theme, "components">> = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: "8px 16px",
        textTransform: "none",
        fontFamily: "Montserrat, sans-serif",
        "&.MuiButton-colorInherit": {
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)", // Lighter hover effect for dark theme
          },
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#1e1e1e", // Slightly lighter than background
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: "#1e1e1e",
        backgroundImage: "none",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        fontFamily: "Montserrat, sans-serif",
        "& .MuiOutlinedInput-root": {
          borderRadius: 8,
          "& fieldset": {
            borderColor: "rgba(255, 255, 255, 0.23)", // Material Design dark theme border color
          },
          "&:hover fieldset": {
            borderColor: "rgba(255, 255, 255, 0.4)",
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontFamily: "Montserrat, sans-serif",
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#1e1e1e",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: "#1e1e1e",
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "rgba(255, 255, 255, 0.12)",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "0.75rem",
      },
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: darkPalette,
  typography: darkTypogarphy,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  components,
};

const ligthTheme: ThemeOptions = {
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  components,
};

const darkTheme = createTheme(darkThemeOptions);

export const APP_THEME = createTheme(ligthTheme);

export type AppTheme = typeof APP_THEME;
export default APP_THEME;
