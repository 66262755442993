import { UploadsDto } from "../dtos";
import qs from "qs";

// Define an interface for the options object
interface FetchOptions extends RequestInit {
  body?: BodyInit | null;
}

class FetchError extends Error {
  details: any;
  data: any;
  status: number;
  constructor({ message, name, status, data }: any = {}) {
    super();
    this.name = name || "FetchError";
    this.message = message || "Fetch error";
    this.data = data || {};
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

export const parseSearchParams = (
  searchString: string = ""
): Record<string, any> => {
  return qs.parse(searchString, { ignoreQueryPrefix: true });
};

const combineSearchParams = (
  searchString: string = "",
  params: Record<string, any> = {}
) => {
  const parsedSearch = parseSearchParams(searchString);
  const mergedSearch =
    typeof params === "object" ? { ...parsedSearch, ...params } : parsedSearch;
  return qs.stringify(mergedSearch);
};

const combinePaths = (baseURL: string, path: string) => {
  return `${baseURL}/${path}`.split("/").filter(Boolean).join("/");
};
export class APIHelper {
  private baseUrl: string;

  constructor(baseUrl: string, origin?: string) {
    this.baseUrl = baseUrl;
    this.fetch = this.fetch.bind(this);
  }

  get origin(): string {
    if (global.location !== undefined) {
      return window.location.origin;
    }
    return `http://localhost:${process.env.PORT || 3000}`;
  }

  private combineUrls(url: string, params: Record<string, any> = {}) {
    const isHttpUrl = url.startsWith("http");
    const fullUrl = new global.URL(url, this.origin);
    fullUrl.pathname = isHttpUrl
      ? fullUrl.pathname
      : combinePaths(this.baseUrl, url);
    const searchString = combineSearchParams(fullUrl.search, params);
    fullUrl.search = searchString;
    return fullUrl.toString();
  }

  private async unwrapJson(res: Response) {
    const data = await res.text();
    try {
      return JSON.parse(data);
    } catch (err) {
      return data;
    }
  }

  private async fetch(
    url: string,
    options: FetchOptions = {},
    params: Record<string, any> = {}
  ): Promise<any> {
    // Construct the full URL
    const fullUrl = this.combineUrls(url, params);

    // Perform the fetch request
    const response = await fetch(fullUrl, options);
    const data = await this.unwrapJson(response);

    if (!response.ok) {
      throw new FetchError({
        message: `request to ${fullUrl} failed with status ${response.status}`,
        data,
        status: response.status,
      });
    }

    return data;
  }

  public get<T = any>(
    url: string,
    params?: Record<string, any>,
    options: FetchOptions = {}
  ): Promise<T> {
    return this.fetch(
      url,
      {
        method: "GET",
        ...options,
      },
      params
    );
  }

  public post<T = any>(
    url: string,
    data?: object,
    params?: Record<string, string>,
    options: FetchOptions = {}
  ): Promise<T> {
    return this.fetch(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        ...options,
        body: JSON.stringify(data),
      },
      params
    );
  }

  public put<T = any>(
    url: string,
    data?: object,
    params?: Record<string, string>
  ): Promise<T> {
    return this.fetch(
      url,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      params
    );
  }

  public save<T = any>(
    url: string,
    data?: object,
    params?: Record<string, string>
  ): Promise<T> {
    const payload: any = data;
    if (payload?._id) {
      url += `/${payload._id}`;
    }
    return this.fetch(
      url,
      {
        method: !!payload?._id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      params
    );
  }

  public delete<T = any>(url: string): Promise<T> {
    return this.fetch(url, {
      method: "DELETE",
    });
  }

  public uploadImages<T = any>(url: string, formData: FormData): Promise<T> {
    return this.fetch(url, { method: "POST", body: formData });
  }

  public upload(formData: FormData): Promise<UploadsDto[]> {
    return this.fetch("/upload", { method: "POST", body: formData });
  }
}

export const SERVER_API = new APIHelper(
  "/api",
  `http://localhost:${process.env.PORT || 3000}`
);

export const API = new APIHelper("/api");
