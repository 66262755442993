import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import { useNavItems } from "./useNavItems";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import "./AppHeader.scss";
import { AdminOnly } from "../AdminOnly/AdminOnly";
import { NavigationEditor, SiteSettingsEditor } from "../../editors";
import { useSiteSettings } from "../../hooks/useSiteSettings";
import { getImageProps } from "../../utils/getImageProps";
import clsx from "clsx";
import { HeaderMenuItem } from "./HeaderMenuItem";
import { useTheme } from "@mui/material";
import loadable from "@loadable/component";
import { useIsMobile } from "../../hooks/useIsMobile";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { LoginFormToggle } from "../FormDialog/LoginFormToggle";

const PrimaryNavMobile = loadable(() => import("./PrimaryNavMobile"));

const SiteLogo = () => {
  const { siteSettings } = useSiteSettings();
  const imgProps = getImageProps(siteSettings.logo);

  if (imgProps) {
    return (
      <Box width={45}>
        <img {...imgProps} className="app-logo" />
      </Box>
    );
  }
  return null;
};

const TOOLBAR_STYLES = {
  minHeight: { xs: 55 },
  height: { xs: 55 },
  justifyContent: { xs: "space-between", md: "flex-start" },
};

type ScrollDirection = "up" | "down";

const useScrollDirection = (isMobile?: boolean): ScrollDirection => {
  const [scrollDirection, setScrollDirection] =
    React.useState<ScrollDirection>("up");
  const prevScrollY = React.useRef<number>(0);
  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    const scrollTarget = isMobile ? document.body : window;
    const handleScroll = () => {
      const currentScrollY = isMobile
        ? (scrollTarget as any).scrollTop
        : window.scrollY;
      setScrollY(currentScrollY);
      if (currentScrollY > prevScrollY.current) {
        setScrollDirection("down");
      } else if (currentScrollY < prevScrollY.current) {
        setScrollDirection(currentScrollY > 0 ? "up" : "down");
      }

      prevScrollY.current = currentScrollY;
    };

    scrollTarget.addEventListener("scroll", handleScroll, { passive: true });

    return () => scrollTarget.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  return scrollY > 0 ? scrollDirection : "down";
};

export function AppHeader() {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const scrollDirection = useScrollDirection(isMobile);
  const { siteSettings } = useSiteSettings();
  const isAdmin = useIsAdmin();
  const LINKS = useNavItems();
  const isSticky = scrollDirection === "up";
  const location = useLocation();

  React.useEffect(() => {
    dispatchEvent(new CustomEvent("app-header-sticky", { detail: isSticky }));
  }, [isSticky]);

  React.useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 1);
        document.body.scrollTo(0, 1);
      }, 0);
    }
  }, [location.key, isMobile]);

  return (
    <AppBar
      elevation={0}
      id="APP_HEADER"
      className={clsx("app-header", {
        "app-header-sticky": isSticky,
        "is-dark-mode": theme.palette.mode === "dark",
      })}
      position="sticky"
    >
      <Container maxWidth="lg">
        <Toolbar sx={TOOLBAR_STYLES} disableGutters>
          <Box display="flex" gap={1} alignItems="center">
            <SiteLogo />
            <RequestQuoteIcon sx={{ fontSize: 36 }} />
            <Box component={Link as any} to="/" sx={{ textDecoration: "none" }}>
              <Typography
                variant="h5"
                noWrap
                sx={{
                  mr: 2,
                  display: { md: "flex" },
                  fontWeight: 700,
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {siteSettings.siteTitle}
              </Typography>
            </Box>
            <AdminOnly>
              <SiteSettingsEditor />
            </AdminOnly>
          </Box>
          {/* <SiteLogo /> */}
          {!isMobile && (
            <Box
              className="primary-nav"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
              alignItems="center"
              justifyContent="flex-end"
              component="nav"
            >
              {LINKS?.map((item) => (
                <HeaderMenuItem item={item} key={item.id} />
              ))}
              <LoginFormToggle />
              <AdminOnly>
                <NavigationEditor selectedNav="app-header" />
              </AdminOnly>
            </Box>
          )}
          {isMobile && <PrimaryNavMobile />}
        </Toolbar>
      </Container>
      {isAdmin && (
        <Box position="absolute" right={{ xs: 100, md: 0 }} id="PAGE_TOOLS" />
      )}
    </AppBar>
  );
}
