import React from "react";

export const Leaf = ({
  attributes: passedAttributes,
  children,
  leaf,
  readOnly,
}: {
  attributes: any;
  children: React.ReactNode;
  leaf: any;
  readOnly: boolean;
}) => {
  const attributes = !readOnly ? passedAttributes : {};
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.style) {
    children = <span style={leaf.style}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};
