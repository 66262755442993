import { useEffect, useRef } from "react";

const useConstructibleRef = <T>(fn: () => T): T => {
  const ref = useRef<T | null>(null);

  if (!ref.current) {
    ref.current = fn();
  }

  // In order to prevent this hook from being a memory leak,
  // we need to explicitly reassign the ref to `null`.
  // Otherwise, this ref is held onto (somehwere) and none
  // of these values are ever GC'd.
  useEffect(
    () => () => {
      ref.current = null;
    },
    []
  );

  return ref.current;
};

export default useConstructibleRef;
