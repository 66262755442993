import isEqual from "fast-deep-equal";
import { useCallback, useEffect, useState } from "react";

interface ResizeObserverOptions {
  contentRectProperties: DOMRectProperties[];
  useBoundingClientRect?: boolean;
}

export enum DOMRectProperties {
  bottom = "bottom",
  height = "height",
  left = "left",
  right = "right",
  top = "top",
  width = "width",
  x = "x",
  y = "y",
}

type ResizeObserverState = { [K in DOMRectProperties]: number | null };

const createResizeObserver = (
  domElement: HTMLElement,
  config: ResizeObserverOptions,
  currentState: ResizeObserverState,
  onStateChange: (newState: ResizeObserverState) => void
) => {
  const observer = new (window as any).ResizeObserver((entries: any) => {
    for (const entry of entries) {
      if (entry.contentRect) {
        let domRect = entry.contentRect;
        if (config.useBoundingClientRect) {
          domRect = domElement.getBoundingClientRect();
        }

        const newState = config.contentRectProperties.reduce(
          (acc, key: DOMRectProperties) => {
            acc[key] = domRect[key];
            return acc;
          },
          {} as ResizeObserverState
        );
        if (!isEqual(newState, currentState)) {
          onStateChange(newState);
        }
      }
    }
  });

  observer.observe(domElement);

  return observer;
};

function useInitialResizeObserverState(config: ResizeObserverOptions) {
  const initialState: ResizeObserverState = config.contentRectProperties.reduce(
    (acc, key: DOMRectProperties) => {
      acc[key] = null;
      return acc;
    },
    {} as ResizeObserverState
  );

  return useState(initialState);
}

export function useResizeObserverWithRefCallback(
  config: ResizeObserverOptions
): [React.RefCallback<HTMLElement>, ResizeObserverState] {
  const [dimensions, setState] = useInitialResizeObserverState(config);
  let observer: any;
  const refCallback = useCallback((node: HTMLElement) => {
    if (node) {
      observer = createResizeObserver(node, config, dimensions, setState);
    } else if (observer) {
      observer.disconnect();
    }
  }, []);
  return [refCallback, dimensions];
}

export default function useResizeObserver(
  domElement: React.RefObject<HTMLElement>,
  config: ResizeObserverOptions
) {
  const [dimensions, setState] = useInitialResizeObserverState(config);

  useEffect(() => {
    if (domElement.current) {
      const observer = createResizeObserver(
        domElement.current,
        config,
        dimensions,
        setState
      );

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [domElement.current, dimensions]); // Be sure to pass in dimensions so it can re-run and get the latest value

  return dimensions;
}
