import { USER_ROLES, UserDto } from "../../dtos";
import { useUser } from "../../providers/UserProvider";
import { Box, Button, Card, CardProps, IconButton } from "@mui/material";
import { getImageProps } from "../../utils/getImageProps";
import { CustomAvatar } from "../Avatar/CustomAvatar";
import styles from "./ProfileCard.module.scss";
import { ProfileUploadButton } from "./ProfileUploadButton";
import { Close } from "@mui/icons-material";
import EditableName from "../EditableName/EditableName";
import { API } from "../../client/API";
import { useIsEditingAllowed } from "../../hooks/useIsEditingAllowed";

type Props = {
  user: UserDto;
  onClose?: () => void;
  elevation?: number;
  variant?: CardProps["variant"];
};

export const ProfileCard = ({
  user,
  onClose,
  elevation = 6,
  variant = "elevation",
}: Props) => {
  const { profilePicture, coverPhoto, username, fullName, bio } = user;
  const { logout } = useUser();
  const isEditingAllowed = useIsEditingAllowed(user, [USER_ROLES.ADMIN]);

  const setField = (field: string) => async (value: string) => {
    const payload = { ...user, [field]: value };
    dispatchEvent(new CustomEvent("USER_UPDATED", { detail: payload }));
    await API.put(`/user/${user._id}`, payload).then((response) => {
      dispatchEvent(new CustomEvent("USER_UPDATED", { detail: response }));
    });
  };

  return (
    <Card
      elevation={elevation}
      variant={variant}
      className={styles.profileCard}
    >
      <Box className={styles.userMedia}>
        <Box className={styles.coverPhoto}>
          {coverPhoto && (
            <img
              className={styles.coverPhotoImg}
              {...getImageProps(coverPhoto)}
            />
          )}
          {!!onClose && (
            <IconButton
              onClick={() => onClose()}
              className={styles.closeButton}
            >
              <Close />
            </IconButton>
          )}
          {isEditingAllowed && (
            <ProfileUploadButton
              className={styles.coverUpload}
              userId={user._id}
              field="coverPhoto"
            />
          )}
        </Box>
        <Box className={styles.userAvatar}>
          <CustomAvatar
            sx={{ height: 100, width: 100 }}
            profilePicture={profilePicture}
            fullName={fullName || ""}
            className={styles.avatar}
          />
          {isEditingAllowed && (
            <ProfileUploadButton
              className={styles.profileUpload}
              userId={user._id}
              field="profilePicture"
            />
          )}
        </Box>
      </Box>
      <Box className={styles.userInfo}>
        <Box className={styles.fullName}>{fullName}</Box>
        <Box className={styles.username}>
          <Box className={styles.bioLabel}>Username:</Box>
          <Box className={styles.bioMessage}>
            <EditableName
              isEditingAllowed={isEditingAllowed}
              label={username}
              setLabel={setField("username")}
            />
          </Box>
        </Box>
        <Box className={styles.biography}>
          <Box className={styles.bioLabel}>Biography:</Box>
          <Box className={styles.bioMessage}>
            <EditableName
              isEditingAllowed={isEditingAllowed}
              label={bio}
              setLabel={setField("bio")}
              isMultiline
            />
          </Box>
        </Box>
      </Box>
      {isEditingAllowed && (
        <Box className={styles.actions}>
          <Button onClick={logout} variant="outlined" fullWidth color="primary">
            Logout
          </Button>
        </Box>
      )}
    </Card>
  );
};
