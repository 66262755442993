import React, { useCallback } from "react";
import { Element } from "../Element/Element";
import { Leaf } from "../Leaf/Leaf";

export const ReadOnlyEditor = ({ content }: { content: any[] }) => {
  const renderLeaf = useCallback(
    ({ key, ...props }: any) => <Leaf key={key} {...props} />,
    []
  );
  const renderNodes = useCallback((nodes: any[]) => {
    if (!nodes?.length) {
      return null;
    }

    return nodes.map((node, idx) => {
      const { children: leafs = [] } = node;
      const childLeafs = leafs.filter((leaf: any) => !!leaf.text);
      const childNodes = leafs.filter(
        (leaf: any) => leaf.type && leaf.children?.length
      );

      const elementChildren = childLeafs.map((leaf: any) =>
        renderLeaf({
          leaf,
          children: leaf.text,
          key: leaf.text,
          readOnly: true,
        })
      );

      const childElements = childNodes?.length ? renderNodes(childNodes) : null;

      return (
        <Element key={idx + node.type} element={node.element || {}} {...node}>
          {elementChildren}
          {childElements}
        </Element>
      );
    });
  }, []);

  return <>{renderNodes(content)}</>;
};
