import React, { FC, useCallback, useMemo } from "react";
import { Box, Breakpoint, Container } from "@mui/material";
import { getWidgetConfig } from "../../../widgets";
import { useIsReadOnly } from "../ReadOnlyContext/ReadOnlyContext";
import { ElementProvider, useElement } from "./PageElementProvider";
import { PluginConfig } from "../../../widgets/types";
import { PageElementDto } from "../../../dtos";

const VALID_CONTAINER_SIZES = ["xl", "lg", "md", "sm", "xs"];
const isValidContainerSize = (containerSize: string) => {
  return VALID_CONTAINER_SIZES.includes(containerSize);
};

const shouldRenderChildren = (elements: PageElementDto[]) => {
  return Array.isArray(elements) && elements.length > 0;
};

interface PageElementProps {
  element: PageElementDto;
}

export const PageElementMarkup: FC<PageElementProps> = ({ element }) => {
  const { setElementRef } = useElement();
  const isReadOnly = useIsReadOnly();

  const {
    id,
    className,
    containerSize,
    isSection,
    type,
    elementType,
    elements,
    sx,
    data = {} as any,
    size,
    spacing,
  } = element as PageElementDto;

  const renderChildren = useCallback(() => {
    if (!shouldRenderChildren(elements ?? [])) {
      return null;
    }

    const childrenJSX = elements?.map((childElement) => {
      return <PageElement key={childElement.id} element={childElement} />;
    });

    if (isValidContainerSize(containerSize ?? "")) {
      const { containerSx = {} } = data;
      return (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
          maxWidth={containerSize as Breakpoint}
        >
          <Box className="container-inner" sx={{ ...containerSx }}>
            {childrenJSX}
          </Box>
        </Container>
      );
    }

    return childrenJSX;
  }, [elements, data, containerSize]);

  const extraProps = isReadOnly
    ? {}
    : {
        ref: setElementRef,
      };

  const config = useMemo<PluginConfig | undefined>(() => {
    return getWidgetConfig(element as PageElementDto);
  }, [type, elementType]);

  const Component = config?.Component as any;
  const preventWrapper = config?.preventWrapper;

  if (preventWrapper && Component) {
    return (
      <Component
        {...extraProps}
        setElementRef={setElementRef}
        {...element}
        data={data}
        size={size}
        sx={sx}
      >
        {renderChildren()}
      </Component>
    );
  }

  const elementJSX = (
    <Box
      {...extraProps}
      minHeight={isSection ? 65 : 0}
      component={isSection ? "section" : "div"}
      id={id}
      className={className}
      sx={sx}
    >
      {Component ? (
        <Component {...element} data={data} size={size} spacing={spacing}>
          {renderChildren()}
        </Component>
      ) : (
        renderChildren()
      )}
    </Box>
  );

  return elementJSX;
};

export const PageElement = (props: PageElementProps) => {
  const isReadOnly = useIsReadOnly();

  if (isReadOnly) {
    return <PageElementMarkup {...props} />;
  }

  return (
    <ElementProvider {...(props as any)}>
      <PageElementMarkup {...props} />
    </ElementProvider>
  );
};
