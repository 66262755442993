import { DotyListPlugin } from "./DotyListPlugin";
import { DotyListEditor } from "./DotyListEditor";
import { PluginConfig } from "../types";
import { SERVER_API, parseSearchParams } from "../../client/API";
import { findSelectedOption } from "./components/DotySortDropdown/constants";

const fetchInitialData = async (element: any, search: string) => {
  try {
    const searchParams = parseSearchParams(search);
    const selectedSort = findSelectedOption(searchParams.sort);
    const sort = { [selectedSort.key]: selectedSort.value };
    const data = await SERVER_API.get(`/dotycandidates?${search}`, { sort });
    return data;
  } catch (err: any) {
    console.log(err.toString());
    return [];
  }
};

const DotyListPluginConfig: PluginConfig = {
  name: "DotyList",
  Component: DotyListPlugin,
  Editor: DotyListEditor,
  fetchInitialData,
};

export default DotyListPluginConfig;
