import React, {
  useEffect,
  FC,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import useConstructibleRef from "../../../hooks/useConstructibleRef";
import { createPortal } from "react-dom";

type Props = {
  customCss: string;
};

const getStyleElement = () => {
  if (typeof document === "undefined") {
    return;
  }
  let styleEl = document.getElementById("PAGE_CSS");

  if (!styleEl) {
    styleEl = document.createElement("style");
    styleEl.id = "PAGE_CSS";
    document.head.appendChild(styleEl);
  }

  return styleEl;
};

export const PageCSS: FC<Props> = ({ customCss = "" }) => {
  const ref = useRef<HTMLStyleElement | undefined>();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const styleElement = useConstructibleRef<HTMLStyleElement | undefined>(
    () => getStyleElement() as HTMLStyleElement
  );

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && styleElement) {
      styleElement.remove();
    }
  }, [isMounted]);

  if (!isMounted) {
    return null;
  }

  return createPortal(
    <style ref={ref as MutableRefObject<HTMLStyleElement>} id="CLIENT_PAGE_CSS">
      {customCss}
    </style>,
    document.head
  );
};
