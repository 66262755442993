import loadable from "@loadable/component";
import { useIsClient } from "../../hooks/useIsClient";
import { ProtectedComponent } from "../ProtectedComponent/ProtectedComponent";

const LazyFormBuilder = loadable(() => import("./FormBuilder"));

const FormBuilder = () => {
  const isClient = useIsClient();

  return isClient ? (
    <ProtectedComponent isLoginEnabled={true} isRegisterEnabled={false}>
      <LazyFormBuilder />
    </ProtectedComponent>
  ) : null;
};

export default FormBuilder;
