import { ButtonProps, SxProps } from "@mui/material";

export interface NavigationItemDto {
  id: string;
  href?: string;
  label?: string;
  target?: string;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
  fullWidth?: boolean;
  links: NavigationItemDto[];
}

export interface NavigationsDto {
  _id: string;
  slug: string;
  links?: Array<NavigationItemDto>;
  title?: string;
}

export interface FieldConditionDto {
  column: string;
  operator: "eq" | "neq" | "contains";
  value: string;
  conditions: FieldConditionDto[];
}

export interface FormFieldDto {
  id: string;
  ts: number;
  name: string;
  type: string;
  label?: string;
  collectionRef?: string;
  collectionFormId?: string;
  isArray?: boolean;
  isRequired?: boolean;
  isUnique?: boolean;
  isRecursive?: boolean;
  isObjectField?: boolean;
  isTitleField?: boolean;
  isFieldsOnly?: boolean;
  isAutoFocus?: boolean;
  options: any[];
  selectOptions?: any[];
  gridProps?: { xs: number; sm: number; md: number; lg: number; xl: number };
  data?: any;
  sx?: SxProps;
  conditions: FieldConditionDto[];
  fields: FormFieldDto[];
  isNew?: boolean;
}

export interface FormDto {
  _id: string;
  id: string;
  formName: string;
  formSlug: string;
  submitUrl: string;
  submitMethod: string;
  submitButtonText: string;
  eventName: string;
  collectionName: string;
  createdAt: Date;
  updatedAt: Date;
  fields?: Array<FormFieldDto>;
  isCollectionTable?: boolean;
  isUserFieldIncluded?: boolean;
  mongooseTemplate?: string;
  strictPopulate?: boolean;
  isCollectionForm?: boolean;
  fieldSize?: "small" | "medium" | "large";
  fieldSizeMobile?: "small" | "medium" | "large";
  timestamps?: boolean;
  version?: number;
  description?: string;
  tags?: string[];
  isActive?: boolean;
}

export interface CollectionSchemasDto {
  _id: string;
  collectionName: string;
  createdAt: Date;
  updatedAt: Date;
  comments?: string;
  dtoTemplate?: string;
  fields?: Array<{
    id: string;
    ts: number;
    name: string;
    type: string;
    collectionRef?: string;
    isArray?: boolean;
    isRequired?: boolean;
    isUnique?: boolean;
    isObjectField?: boolean;
    fields: CollectionSchemasDto["fields"];
  }>;
  isCollectionTable?: boolean;
  isUserFieldIncluded?: boolean;
  mongooseTemplate?: string;
  strictPopulate?: boolean;
  timestamps?: boolean;
}

export enum USER_ROLES {
  ADMIN = "ADMIN",
  AUTHOR = "AUTHOR",
  EDITOR = "EDITOR",
  USER = "USER",
}

export interface UserRoleDto {
  _id: string;
  createdAt: Date;
  name: string;
  type: USER_ROLES;
  updatedAt: Date;
}

export interface UserDto {
  _id: string;
  createdAt: Date;
  emailAddress: string;
  profilePicture?: UploadsDto;
  coverPhoto?: UploadsDto;
  password: string;
  updatedAt: Date;
  username: string;
  fullName?: string;
  role: UserRoleDto;
  bio: string;
}

export interface PageElementDto {
  id: string;
  className?: string;
  sx?: object;
  data?: object;
  containerSize?: string;
  isSection?: boolean;
  type: string;
  elementType: "SECTION" | "ROW" | "CELL" | "WIDGET";
  elements?: PageElementDto[];
  size?: number;
  spacing?: number;
  displayName?: string;
}

export interface PagesDto {
  _id: string;
  slug: string;
  attachments?: string;
  customCss?: string;
  description?: string;
  elements: Array<PageElementDto>;
  featuredImage: UploadsDto;
  title?: string;
  keywords?: string;
  showKeywords?: boolean;
}

export interface UploadFormatDto {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: string;
  url: string;
}

export interface UploadsDto {
  _id: string;
  id: string;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    thumbnail: UploadFormatDto;
    xs: UploadFormatDto;
    sm: UploadFormatDto;
    md: UploadFormatDto;
    lg: UploadFormatDto;
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted?: boolean;
}

export interface SiteSettingsDto {
  _id?: string;
  siteId: string;
  customCss?: string;
  logo?: UploadsDto;
  siteTitle?: string;
  seoTitle?: string;
  siteUrl?: string;
}

export interface DotyCandidatesDto {
  _id: string;
  fullName: string;
  images: UploadsDto[];
  votes: any[];
  commentsCount: number;
  votesCount: number;
  totalComments: number;
  description?: string;
  instagramHandle?: string;
  createdBy?: UserDto;
  updatedBy?: UserDto;
  hasVoted?: boolean;
}

export interface ReplyDto {
  _id: string;
  user: UserDto;
  createdAt: string;
  updatedAt: string;
  comment: string;
  replies: ReplyDto[];
  ts: Number;
}

export interface CommentsDto {
  _id: string;
  user: UserDto;
  createdAt: string;
  updatedAt: string;
  comment: string;
  onModel: string;
  referenceId: string;
  replies: ReplyDto[];
  ts?: number;
}

export interface PageViewsDto {
  _id: string;
  data?: object;
  ipAddress?: string;
  requestUrl?: string;
  responseTime?: number;
  status?: string;
}

export interface GalleriesDto {
  _id: string;
  description: string;
  images: UploadsDto[];
  title: string;
  type: string;
  user: string;
}

export interface RoomEstimateDto {
  roomType: string;
  length: number;
  width: number;
  flooringDetails?: {
    isNewFlooring?: boolean;
    flooringType: string;
  };
  kitchenDetails?: {
    isNewAppliances?: boolean;
    isCabinets?: boolean;
    cabinetType: string;
    otherCabinetType: string;
    isNewCounters?: boolean;
    countertopType: string;
  };
  bathroomDetails?: {
    isNewShower?: boolean;
    isNewVanity?: boolean;
    isNewToilet?: boolean;
  };
  plumbingDetails?: {
    isMovingPlumbing?: boolean;
    moveDistance?: number;
  };
  products?: Array<{
    product: ProductsDto;
    quantity?: number;
  }>;
  services?: Array<{
    service: ServicesDto;
    numHours?: number;
  }>;
  images?: UploadsDto[];
  description?: string;
}
export interface EstimatesDto {
  _id: string;
  emailAddress: string;
  fullName: string;
  phoneNumber: string;
  comments?: string;
  projectDetails?: Array<RoomEstimateDto>;
}

export type FieldTypeDto = {
  _id: string;
  label: string;
  type: string;
  value: string;
  customComponent?: string;
};

export interface ProductsDto {
  _id: string;
  productName: string;
  categories?: string[];
  isPricePerSqft?: boolean;
  productDescription?: string;
  productPrice?: number;
}

export interface ServicesDto {
  _id: string;
  serviceName: string;
  price: number;
}

export interface SuggestionDto {
  _id: string;
  value: string;
  label: string;
}

export interface LaborCostsDto {
  _id: string;
  carpentry?: {
    roughCost?: number;
    finishCost?: number;
  };
  electrical?: {
    roughCost?: number;
    finishCost?: number;
  };
  plumbing?: {
    roughCost?: number;
    finishCost?: number;
  };
}

export interface CtaDto {
  _id: string;
  label: string;
  href: string;
  className?: string;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  sx?: SxProps;
  ts: number;
}

export interface DotyVotesDto {
  _id: string;
  dotyId: string;
  voterId: string;
  ts: number;
}

export interface AppDataDto {
  navigations: NavigationsDto[];
}
