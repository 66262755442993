import React, { ReactNode } from "react";
import { WidgetDto } from "../types";
import { Breakpoint, Grid } from "@mui/material";
import { ResizableCell } from "../../components/ResizableCell/ResizableCell";
import { useIsReadOnly } from "../../pages/Page/ReadOnlyContext/ReadOnlyContext";

const AUTO_GRID_PROPS = { xs: 12, sm: "auto" };

const buildGridProps = (grid: Record<Breakpoint, any>): any => {
  return ["xs", "sm", "md", "lg", "xl"].reduce(
    (gridProps: Record<Breakpoint, any>, breakpoint: string) => {
      const value = grid[breakpoint as Breakpoint];
      if (value && !isNaN(parseInt(value))) {
        return { ...gridProps, [breakpoint]: parseInt(value) };
      }
      return gridProps;
    },
    {} as Record<Breakpoint, any>
  );
};

export const CellPlugin: React.FC<
  WidgetDto & {
    setElementRef?: (el: HTMLDivElement) => void;
    children?: ReactNode;
  }
> = ({ data = {}, size, children, sx = {}, setElementRef }) => {
  const isReadOnly = useIsReadOnly();

  const { grid = {}, isFlexAuto = false } = data;

  const gridPrips = isFlexAuto ? AUTO_GRID_PROPS : buildGridProps(grid);

  if (!isReadOnly && !isFlexAuto) {
    return (
      <ResizableCell
        sx={sx}
        sm={size as any}
        {...gridPrips}
        flex={isFlexAuto ? "auto" : undefined}
      >
        {children}
      </ResizableCell>
    );
  }

  return (
    <Grid
      item
      sm={size as any}
      {...gridPrips}
      flex={isFlexAuto ? "auto" : undefined}
      sx={sx}
      ref={setElementRef}
    >
      {children}
    </Grid>
  );
};
