import React, { ReactNode, useEffect, useState } from "react";
import { useFormSchema } from "../../widgets/hooks/useFormSchema";
import { FormDto, USER_ROLES, UserDto } from "../../dtos";
import { Box, Button, capitalize, SxProps } from "@mui/material";
import useToggle from "../../hooks/useToggle";
import { Dialog } from "../CustomDialog/CustomDialog";
import { FormSchemaForm } from "../FormSchemaForm";
import { useIsEditingAllowed } from "../../hooks/useIsEditingAllowed";

interface Props {
  isLoginEnabled?: boolean;
  isRegisterEnabled?: boolean;
  roles?: USER_ROLES[];
  children?: ReactNode;
  loginMessage?: string;
  initialUser?: UserDto | null;
  sx?: SxProps;
}

const DEFAULT_LOGIN_MESSAGE = "Login to access this area.";

export const ProtectedComponent: React.FC<Props> = ({
  isLoginEnabled = true,
  isRegisterEnabled = true,
  children,
  loginMessage,
  initialUser = null,
  roles = [USER_ROLES.USER],
  sx,
}) => {
  const [isLoginFormOpen, toggleIsLoginFormOpen] = useToggle(false);
  const {
    formSchema: loginForm,
    isLoading: loginLoading,
    fetchFormSchema: fetchLoginForm,
  } = useFormSchema("login-form", false);
  const {
    formSchema: registerForm,
    isLoading: registerLoading,
    fetchFormSchema: fetchRegisterForm,
  } = useFormSchema("register-form", false);
  const [view, setView] = useState<"login" | "register">("login");
  const isFormLoading = view === "login" ? loginLoading : registerLoading;
  const isUserAllowed = useIsEditingAllowed(initialUser, roles);

  const closeLoginForm = () => {
    toggleIsLoginFormOpen();
    setView("login");
  };

  useEffect(() => {
    const form = view === "login" ? loginForm : registerForm;
    const fetchForm = view === "login" ? fetchLoginForm : fetchRegisterForm;
    if (isLoginFormOpen && !form) {
      fetchForm?.();
    }
  }, [isLoginFormOpen, view]);

  if (!isUserAllowed && !isLoginEnabled) {
    return null;
  }

  const renderLoginMessage = () => {
    return (
      <Box>
        {loginMessage || DEFAULT_LOGIN_MESSAGE}
        {isRegisterEnabled && (
          <Button
            onClick={() => {
              setView("register");
              toggleIsLoginFormOpen();
            }}
          >
            Register
          </Button>
        )}
      </Box>
    );
  };

  if (!isUserAllowed && isLoginEnabled) {
    return (
      <Box
        display="flex"
        padding={1}
        gap={1}
        borderRadius={1}
        border="1px solid rgba(100, 100, 100, .5)"
        sx={sx}
      >
        <Box>{renderLoginMessage()}</Box>
        <Box>
          <Button onClick={toggleIsLoginFormOpen} variant="outlined">
            Login
          </Button>
        </Box>
        <Dialog
          maxWidth="xs"
          fullWidth
          isOpen={isLoginFormOpen}
          onClose={closeLoginForm}
        >
          <Dialog.Header>{capitalize(view)}</Dialog.Header>
          <Dialog.Content>
            {!isFormLoading && (
              <FormSchemaForm
                form={
                  (view === "register" ? registerForm : loginForm) as FormDto
                }
                isDefaultSubmit
                isFieldsOnly
              />
            )}
          </Dialog.Content>
        </Dialog>
      </Box>
    );
  }

  return <>{children}</>;
};
