import { FormControlLabel, Switch } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  children?: React.ReactNode;
  label?: string;
}

export const ToggleSwitch: React.FC<Props> = ({
  isChecked = false,
  onChange,
  label,
  children,
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange(checked);
  };
  return (
    <FormControlLabel
      control={
        <Switch checked={!!isChecked} onChange={handleChange} name="gilad" />
      }
      label={label || children}
    />
  );
};
