import React, { useState, useEffect, useCallback, RefObject } from "react";
import { deepEquals } from "../../../../utils/deepEquals";

interface Position {
  x: number;
  y: number;
}

interface UseDraggableProps {
  onClose: () => void;
}

interface UseDraggableReturn {
  position: Position;
  isDragging: boolean;
  ref: RefObject<HTMLDivElement>;
}

const useDraggable = ({ onClose }: UseDraggableProps): UseDraggableReturn => {
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const startPosRef = React.useRef<Position>({ x: 0, y: 0 });
  const ref = React.useRef<HTMLDivElement>(null);
  const [prevPos, setPrevPos] = useState<Position>({ x: 0, y: 0 });
  const positionRef = React.useRef<Position>({ x: 0, y: 0 });
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    positionRef.current = { ...position };
    timeoutRef.current = setTimeout(() => {
      setPrevPos({ ...position });
    }, 400);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [position]);

  const getClientCoords = (e: MouseEvent | TouchEvent): Position => {
    if ("touches" in e) {
      return { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
    return { x: e.clientX, y: e.clientY };
  };

  const handleStart = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (ref.current) {
        const coords = getClientCoords(e);
        startPosRef.current = coords;
        setIsDragging(true);
      }
    },
    [ref]
  );

  const handleMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (isDragging) {
        const coords = getClientCoords(e);
        const deltaX = coords.x - startPosRef.current.x;
        const deltaY = coords.y - startPosRef.current.y;
        setPosition({ x: deltaX, y: deltaY > 0 ? deltaY : 0 });
      }
    },
    [isDragging]
  );

  const handleEnd = useCallback(() => {
    setIsDragging(false);

    if (deepEquals(positionRef.current, prevPos)) {
      setPosition({ x: 0, y: 0 });
      setPrevPos({ x: 0, y: 0 });
      return;
    }
    if (positionRef.current.y > prevPos.y) {
      onClose();
    } else {
      setPosition({ x: 0, y: 0 });
      setPrevPos({ x: 0, y: 0 });
    }
  }, [prevPos]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener("mousedown", handleStart);
    element.addEventListener("touchstart", handleStart);

    const moveEvents = ["mousemove", "touchmove"];
    const endEvents = ["mouseup", "touchend"];

    moveEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleMove as EventListener, {
        passive: false,
      });
    });

    endEvents.forEach((eventName) => {
      document.addEventListener(eventName, handleEnd);
    });

    return () => {
      if (element) {
        element.removeEventListener("mousedown", handleStart);
        element.removeEventListener("touchstart", handleStart);
      }

      moveEvents.forEach((eventName) => {
        document.removeEventListener(eventName, handleMove as EventListener);
      });

      endEvents.forEach((eventName) => {
        document.removeEventListener(eventName, handleEnd);
      });
    };
  }, [ref.current, handleStart, handleMove, handleEnd]);

  // Prevent default touch behavior to avoid scrolling while dragging
  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (isDragging) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventDefault, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventDefault);
    };
  }, [isDragging]);

  return { ref, position, isDragging };
};

export default useDraggable;
