import { useCallback, useEffect, useState } from "react";
import { useAppData } from "../../providers/AppDataProvider";
import { DotyCandidatesDto } from "../../dtos";
import { useParams } from "react-router-dom";
import { API } from "../../client/API";

export const useDoty = () => {
  const { appData } = useAppData();
  const [doty, setDoty] = useState<DotyCandidatesDto>(appData.doty);
  const [isLoading, setIsLoading] = useState(!doty);
  const { dotyId } = useParams<Record<string, string>>();

  const fetchDoty = useCallback(async (id: string = dotyId || "") => {
    setIsLoading(true);
    const data = await API.get<DotyCandidatesDto>(
      `/dotycandidates/${id}`
    ).catch((err) => null);

    if (data) setDoty(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchDoty(dotyId);
  }, [dotyId]);

  return { doty, setDoty, isLoading, dotyId, fetchDoty };
};
