import { Box, Paper } from "@mui/material";
import classNames from "clsx";
import React, {
  type ReactNode,
  type TransitionEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import "./SwipableBottomDrawer.module.scss";
import { useIsClient } from "../../../../../hooks/useIsClient";
import { NOOP } from "../../../../../utils/NOOP";
import useDraggable from "../useDraggable";
import styles from "./SwipableBottomDrawer.module.scss";

type Props = {
  isOpen: boolean;
  className?: string;
  hideBackBtn?: boolean;
  onClose?: () => void;
  showBackdrop?: boolean;
  containerElement?: HTMLElement;
  children?: ReactNode;
};

const SwipableBottomDrawer: React.FC<Props> = ({
  isOpen,
  onClose = NOOP,
  hideBackBtn,
  className,
  children,
  showBackdrop = true,
  containerElement,
}) => {
  const isClient = useIsClient();
  const overlayElementRef = useRef<HTMLDivElement>(null);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen);
  const {
    ref: headerRef,
    position,
    isDragging,
  } = useDraggable({ onClose: onClose });

  const containerEl = useMemo<HTMLElement | null>(() => {
    if (!isClient) {
      return null;
    }
    return containerElement || document.body;
  }, [containerElement, isClient]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setIsPanelOpen(isOpen);

    const handleClose = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // stop modal from closing
        e.stopPropagation();
        onClose();
      }
    };

    document.body.addEventListener("keydown", handleClose, false);

    return () => {
      document.body.removeEventListener("keydown", handleClose, false);
    };
  }, [isOpen]);

  // when passed isOpen prop changes to false, run animation before unmounting
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    const { target, currentTarget } = e;
    if (target === currentTarget) {
      setIsPanelOpen(isOpen);
    }
  };

  const shouldRenderPortal = (isPanelOpen || isOpen) && containerEl;
  const isEntering = isOpen && !isPanelOpen;
  const isLeaving = isPanelOpen && !isOpen;

  useEffect(() => {
    if (shouldRenderPortal) {
      document.body.style.overflow = "hidden";
      document.body.style.height = window.innerHeight + "px";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
  }, [shouldRenderPortal]);

  if (!shouldRenderPortal) {
    return null;
  }

  return createPortal(
    <>
      <Box
        className={classNames(styles.bottomDrawer, className, {
          [styles.isEntering]: isEntering,
          [styles.isLeaving]: isLeaving,
        })}
        id="BOTTOM_DRAWER"
        data-drawer="true"
        onClick={(e) => e.stopPropagation()}
        ref={overlayElementRef}
        onTransitionEnd={handleTransitionEnd}
        boxShadow={6}
        style={{
          transition: isDragging ? "none" : undefined,
          transform: isDragging ? `translateY(${position.y}px)` : undefined,
        }}
      >
        <Box
          component={Paper}
          borderRadius="12px 12px 0 0"
          borderTop="1px solid rgba(100,100,100,0.5)"
          maxHeight="75vh"
          overflow="hidden"
          display="flex"
          flexDirection="column"
        >
          <Box
            ref={headerRef}
            flex={1}
            textAlign="center"
            fontWeight={600}
            py={1}
            pb={1}
          >
            <Box
              width={50}
              height={4}
              borderRadius={4}
              margin="auto"
              mb={1}
              bgcolor="rgba(100,100,100,0.5)"
            ></Box>
            <Box>Comments</Box>
          </Box>
          {children}
        </Box>
      </Box>
      {showBackdrop && (
        <div
          onClick={onClose}
          className={classNames(styles.backDrop, {
            [styles.backdropEntering]: isEntering,
            [styles.backdropLeaving]: isLeaving,
          })}
        />
      )}
    </>,
    containerEl
  );
};

export default SwipableBottomDrawer;
