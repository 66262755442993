import React, {
  createContext,
  useContext,
  FC,
  useState,
  PropsWithChildren,
} from "react";

const AppData = createContext<any>({});

type Props = {
  appData: any;
};

export const AppDataProvider: FC<PropsWithChildren<Props>> = ({
  appData: passedAppData,
  children,
}) => {
  const [appData, setAppData] = useState<any>(passedAppData);

  return (
    <AppData.Provider value={{ appData, setAppData }}>
      {children}
    </AppData.Provider>
  );
};

export function useAppData() {
  return useContext(AppData);
}
