import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavItems } from "../AppHeader/useNavItems";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";

export function AppFooter() {
  const navItems = useNavItems("app-footer-nav");
  const isMobile = useIsMobile();
  return (
    <Box
      id="APP_FOOTER"
      borderTop="1px solid rgba(100, 100, 100, 0.3)"
      py={2}
      pt={3}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            Copyright <b>QuoteGenie</b> {new Date().getFullYear()}. All
            rights reserved
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              <Box
                display="flex"
                width={isMobile ? "100%" : "auto"}
                flexDirection={isMobile ? "column" : "row"}
                component="nav"
              >
                {navItems?.map((item) => {
                  return (
                    <Button
                      fullWidth={isMobile}
                      key={item.id}
                      component={Link as any}
                      to={item.href}
                    >
                      {item.label}
                    </Button>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
