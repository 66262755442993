import { useMemo } from "react";
import { useAppData } from "../../providers/AppDataProvider";
import { NavigationsDto } from "../../dtos";

type NavSlug = "app-header" | "primary-nav-mobile" | "app-footer-nav";

const selectPrimaryNavItems = (
  appData: any,
  slug: NavSlug = "app-header"
): NavigationsDto["links"] => {
  return (
    appData?.navigations?.find((nav: any) => nav.slug === slug)?.links || []
  );
};

export function useNavItems(
  slug: NavSlug = "app-header"
): NavigationsDto["links"] {
  const { appData } = useAppData();

  return useMemo(() => {
    return selectPrimaryNavItems(appData, slug);
  }, [appData?.navigations, slug]);
}
