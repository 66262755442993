import { useState, useEffect } from "react";

export const useIsAppHeaderSticky = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleStickyChange = (e: CustomEvent) => {
      setIsSticky(e.detail);
    };
    window.addEventListener(
      "app-header-sticky",
      handleStickyChange as EventListener,
      false
    );

    return () => {
      window.removeEventListener(
        "app-header-sticky",
        handleStickyChange as EventListener,
        false
      );
    };
  }, []);

  return isSticky;
};
