import React, { useEffect } from "react";
import { WidgetDto } from "../types";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import useToggle from "../../hooks/useToggle";
import { ProtectedFormDialog } from "./FormDialog";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import { API } from "../../client/API";

export const FormDialogPlugin: React.FC<WidgetDto> = ({ data }) => {
  const [isOpen, toggleDialog, open, close] = useToggle();
  const [initialValues, setInitialValues] = React.useState<any>();
  const {
    formId,
    dialogTitle,
    toggleText = "Settings",
    isProtected,
    initalValuesId,
    collectionRef,
  } = data;

  useEffect(() => {
    if (initalValuesId && collectionRef) {
      API.get(`/${collectionRef.toLowerCase()}/${initalValuesId}`)
        .then(setInitialValues)
        .catch(() => {
          // setInitialValues({});
        });
    } else {
      setInitialValues({});
    }
  }, [initalValuesId, collectionRef]);

  return (
    <>
      <Button variant="outlined" onClick={toggleDialog}>
        {toggleText}
      </Button>
      {initialValues && formId && (
        <FormDialog
          isOpen={isOpen}
          onClose={close}
          initialValues={initialValues}
          formSlug={formId}
        />
      )}
    </>
  );
};
