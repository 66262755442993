import { useUser } from "../../providers/UserProvider";
import useToggle from "../../hooks/useToggle";
import { Button, Dialog } from "@mui/material";
import { FormDialog } from "./FormDialog";
import { ProfileCard } from "../ProfileCard/ProfileCard";

export const LoginFormToggle = () => {
  const { user } = useUser();
  const [isOpen, toggleIsOpen] = useToggle();

  if (user?._id) {
    return (
      <>
        <Button sx={{ justifyContent: "flex-start" }} onClick={toggleIsOpen}>
          Profile
        </Button>
        <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={toggleIsOpen}>
          <ProfileCard user={user} onClose={toggleIsOpen} />
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Button sx={{ justifyContent: "flex-start" }} onClick={toggleIsOpen}>
        Login
      </Button>
      <FormDialog
        isOpen={isOpen}
        onClose={toggleIsOpen}
        formSlug="login-form"
        initialValues={{}}
        maxWidth="xs"
      />
    </>
  );
};
