import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogProps,
  IconButton,
  Paper,
} from "@mui/material";
import React, { FormEvent, useCallback, useState } from "react";
import { useFormSchema } from "../../widgets/hooks/useFormSchema";
import { FormSchemaForm } from "../FormSchemaForm";
import styles from "./FormDialog.module.scss";
import { Close } from "@mui/icons-material";
import { API } from "../../client/API";
import { useIsMobile } from "../../hooks/useIsMobile";
import clsx from "clsx";

type Props<T = any> = {
  formSlug: string;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (values: T) => void;
  initialValues?: any;
  onSubmitSuccess?: (values: T) => void;
  onSubmitError?: (error: Error) => void;
  onSubmit?: (values: T) => Promise<void>;
  isMobile?: boolean;
};

function FormDialogPaperComponent<T = any>({
  formSlug,
  onClose,
  initialValues,
  onSubmit,
  onChange,
  onSubmitSuccess,
  onSubmitError,
  isMobile,
}: Omit<Props<T>, "isOpen">) {
  const [values, setValues] = useState(initialValues || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formSchema, isLoading } = useFormSchema(formSlug);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { submitUrl, submitMethod, eventName } = formSchema || {};

    if (isSubmitting) return;

    setIsSubmitting(true);

    if (onSubmit) {
      const data = await onSubmit(values);
      onSubmitSuccess?.(data as any);
      onClose();
      return;
    }

    if (submitUrl && submitMethod) {
      const method = submitMethod.toLowerCase() as keyof typeof API;
      if (typeof API[method] === "function") {
        await (API[method] as Function)(submitUrl, values)
          .then((results: any) => {
            window.dispatchEvent(
              new CustomEvent(eventName || "", { detail: results })
            );
            onSubmitSuccess?.(results);
            onClose();
            setIsSubmitting(false);
          })
          .catch((error: any) => {
            setIsSubmitting(false);
            onSubmitError?.(error);
          });
      }
    }
  };

  const handleChange = (nextValues: T) => {
    setValues(nextValues);
    onChange?.(nextValues);
  };

  if (isLoading) {
    return (
      <Paper
        elevation={6}
        sx={{
          height: isMobile ? "100%" : undefined,
          minHeight: 300,
          width: "100%",
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={60} />
      </Paper>
    );
  }

  return (
    <Paper
      onSubmit={handleSubmit}
      component="form"
      className={clsx(styles.formWrapper, {
        [styles.formWrapperMobile]: isMobile,
      })}
      sx={{
        borderRadius: 1,
        boxShadow: 6,
      }}
    >
      <Box className={styles.formHeader}>
        <Box className={styles.formTitle}>
          <b>{formSchema?.formName}</b>
        </Box>
        <IconButton
          size="small"
          onClick={onClose}
          type="button"
          className={styles.closeButton}
        >
          <Close />
        </IconButton>
      </Box>
      <Box className={styles.formBody}>
        {formSchema && (
          <FormSchemaForm
            form={formSchema}
            initialValues={initialValues}
            onChange={handleChange as any}
            isFieldsOnly
          />
        )}
      </Box>
      <Box className={styles.formFooter}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          startIcon={
            isSubmitting ? (
              <CircularProgress size={16} color="inherit" />
            ) : undefined
          }
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
        >
          {formSchema?.submitButtonText || "Submit"}
        </Button>
      </Box>
    </Paper>
  );
}

export function FormDialog<T = any>({
  formSlug,
  isOpen,
  onClose,
  initialValues,
  maxWidth = "sm",
  onSubmit,
  onChange,
  onSubmitSuccess,
  onSubmitError,
}: Props<T> & Omit<DialogProps, "open" | "onSubmit" | "onChange">) {
  const isMobile = useIsMobile();
  const renderForm = useCallback(() => {
    return (
      <Container
        sx={{
          maxHeight: isMobile ? "100%" : "calc(100vh - 80px)",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          flexDirection: "column",
          height: isMobile ? "100%" : undefined,
          px: isMobile ? 0 : undefined,
        }}
        maxWidth={maxWidth}
      >
        <FormDialogPaperComponent
          isMobile={isMobile}
          formSlug={formSlug}
          onClose={onClose}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onChange={onChange}
          onSubmitError={onSubmitError}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Container>
    );
  }, [formSlug, isMobile, maxWidth, initialValues]);

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      fullScreen={isMobile}
      open={isOpen}
      onClose={onClose}
      PaperComponent={isOpen ? renderForm : undefined}
    />
  );
}
