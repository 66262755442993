import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  PropsWithChildren,
} from "react";
import { PagesDto } from "../../../dtos";
import { usePageEditorProvider } from "./usePageEditorProvider";

const PageEditorContext = createContext<ReturnType<
  typeof usePageEditorProvider
> | null>(null);

type PageProviderProps = {
  page: PagesDto;
  setPage: Dispatch<SetStateAction<PagesDto>>;
};

export const PageProvider: FC<PropsWithChildren<PageProviderProps>> = ({
  page,
  setPage,
  children,
}) => {
  const pageStore = usePageEditorProvider(page, setPage);

  return (
    <PageEditorContext.Provider value={pageStore}>
      {children}
    </PageEditorContext.Provider>
  );
};

export const usePageEditor = (): ReturnType<typeof usePageEditorProvider> => {
  const context = useContext(PageEditorContext);

  if (!context) {
    throw new Error("You must be logged in to edit");
  }

  return context as ReturnType<typeof usePageEditorProvider>;
};
