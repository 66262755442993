import { FormPlugin } from "./FormPlugin";
import { FormEditor } from "./FormEditor";
import { PluginConfig, WidgetDto } from "../types";
import { SERVER_API } from "../../client/API";

const fetchInitialData = async (element: any, search: string) => {
  try {
    const id = element?.data?.formId;
    if (id) {
      const data = await SERVER_API.get(`/forms/${id}`);
      return data;
    }
    throw new Error("formId not set");
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

const FormPluginConfig: PluginConfig = {
  name: "form-plugin",
  Component: FormPlugin as React.FC<WidgetDto>,
  Editor: FormEditor,
  allowsChildren: true,
  preventWrapper: true,
  fetchInitialData,
};

export default FormPluginConfig;
