import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  createContext,
} from "react";
import { DotyCandidatesDto } from "../../dtos";
import { useSelectedSort } from "./components/DotySortDropdown/DotySortDropdown";
import { API } from "../../client/API";
import { deepEquals } from "../../utils/deepEquals";

type DotyHookProps = {
  dotys: DotyCandidatesDto[];
  setDotys: React.Dispatch<React.SetStateAction<DotyCandidatesDto[]>>;
  setDoty: (doty: DotyCandidatesDto) => void;
  removeDoty: (dotyId: string) => void;
};

export const useDotysProvider = (
  initialData: DotyCandidatesDto[] = []
): DotyHookProps => {
  const [dotys, setDotys] = useState<DotyCandidatesDto[]>(initialData);
  const { getApiSortParam } = useSelectedSort();
  const prevSort = useRef<Record<string, any>>({});

  const fetchDotys = useCallback(async (searchParams = {}) => {
    await API.get(`/dotycandidates`, searchParams).then(setDotys);
  }, []);

  const handleDotySaved = useCallback(
    (event: CustomEvent<any>) => {
      const dotySaved = event.detail;
      const foundDoty = dotys.some((doty) => doty._id === dotySaved?._id);
      if (foundDoty) {
        setDotys((prevDotys: DotyCandidatesDto[]) =>
          prevDotys.map((doty) => {
            if (doty._id === dotySaved?._id) {
              return { ...dotySaved };
            }
            return doty;
          })
        );
      } else {
        fetchDotys({ sort: getApiSortParam() });
      }
    },
    [dotys, getApiSortParam]
  );

  useEffect(() => {
    window.addEventListener(
      "DOTY_SAVED",
      handleDotySaved as EventListener,
      false
    );

    return () => {
      window.removeEventListener(
        "DOTY_SAVED",
        handleDotySaved as EventListener,
        false
      );
    };
  }, [handleDotySaved]);

  useEffect(() => {
    const sort = getApiSortParam();
    if (!deepEquals(sort, prevSort.current)) {
      fetchDotys({ sort });
      prevSort.current = sort;
    }
  }, [fetchDotys, getApiSortParam]);

  const setDoty = useCallback((doty: DotyCandidatesDto) => {
    setDotys((prevDotys: DotyCandidatesDto[]) =>
      prevDotys.map((d) => (d._id === doty._id ? doty : d))
    );
  }, []);

  const removeDoty = useCallback((dotyId: string) => {
    setDotys((prevDotys) => prevDotys.filter((doty) => doty._id !== dotyId));
  }, []);

  return { dotys, setDotys, setDoty, removeDoty };
};

export const DotyListContext = createContext<DotyHookProps | undefined>(
  undefined
);

export const useDotyListProps = () => {
  const context = useContext(DotyListContext);

  if (!context) {
    throw new Error("DotyListContext not set up");
  }

  return context;
};
