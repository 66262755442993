import { generateId } from "./generateId";

type BaseElement = {
  id: string | number;
};

type BaseModelDto = {
  _id: string;
};

type ConvertedModelDto<T extends BaseModelDto = BaseModelDto> = BaseElement & T;

type Convert<V, O extends object> = {
  [Key in keyof O]: V;
};

export type FindProps<T> = T & {
  index: number;
  ancestorIds: Array<string | number>;
};

export const convertModelsToDtos = <T extends BaseModelDto = BaseModelDto>(
  models: Array<T>,
  recursiveField: keyof T
): ConvertedModelDto<T>[] => {
  return models.map((model) => {
    const { _id } = model;
    const childModels = model[recursiveField] as T[];

    if (childModels?.length) {
      return {
        ...model,
        id: _id,
        [recursiveField]: convertModelsToDtos(childModels, recursiveField),
      };
    }

    return {
      ...model,
      id: _id,
    };
  });
};

export const findElementDeep = <T extends BaseElement = BaseElement>(
  items: T[],
  selectedId: string,
  recursiveField: keyof T,
  uniqueId: keyof T = "id",
  ancestorIds: Array<string | number> = [],
  path: number[] = []
): FindProps<T> | undefined => {
  let count = 0;
  for (const item of items) {
    const id = item[uniqueId];
    const childItems = item[recursiveField] as T[];

    if (id === selectedId) {
      const foundItem = {
        ...item,
        ancestorIds,
        index: count,
        path: [...path, count],
      };
      count = count + 1;
      return foundItem;
    }

    count = count + 1;

    if (childItems?.length) {
      const child = findElementDeep(
        childItems,
        selectedId,
        recursiveField,
        uniqueId,
        [...ancestorIds, id as string | number],
        [...path, count]
      );

      if (child) {
        return child;
      }
    }
  }

  return undefined;
};

export const removeElementDeep = <T extends BaseElement = BaseElement>(
  items: T[],
  selectedId: string,
  recursiveField: keyof T
): T[] => {
  return items
    .filter((item) => item.id !== selectedId)
    .map((element) => {
      const childItems = element[recursiveField] as T[];
      return {
        ...element,
        [recursiveField]: childItems?.length
          ? removeElementDeep(childItems, selectedId, recursiveField)
          : [],
      };
    });
};

export const updateElementDeep = <T extends BaseElement = BaseElement>(
  updatedElement: T,
  elements: T[],
  recursiveField: keyof T
): T[] => {
  return elements.map((element) => {
    if (element.id === updatedElement.id) {
      return { ...element, ...updatedElement };
    }
    const childElements = element[recursiveField] as T[];
    if (childElements?.length) {
      return {
        ...element,
        [recursiveField]: updateElementDeep(
          updatedElement,
          childElements,
          recursiveField
        ),
      };
    }
    return element;
  });
};

export const addChildToElement = <T extends BaseElement = BaseElement>(
  parentId: string,
  newElement: T,
  elements: T[],
  recursiveField: keyof T
): T[] => {
  return elements.map((element) => {
    const { id } = element;
    const childElements = element[recursiveField] as T[];

    if (id === parentId) {
      return {
        ...element,
        [recursiveField]: [...childElements, newElement],
      };
    }

    if (!childElements.length) {
      return element;
    }

    return {
      ...element,
      [recursiveField]: addChildToElement(
        parentId,
        newElement,
        childElements,
        recursiveField
      ),
    };
  });
};

export const replaceIdsRecursively = (obj: any): any => {
  // Handle non-objects and null
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // Handle arrays using Array.map
  if (Array.isArray(obj)) {
    return obj.map(replaceIdsRecursively);
  }

  const handleIdReplacement = (key: string, value: any) => {
    if (key === "id") {
      return generateId("clone");
    }
    if (key === "_id") {
      return undefined;
    }
    if (typeof value === "object" && value !== null) {
      return replaceIdsRecursively(value);
    }
    return value;
  };

  // Use Object.entries and reduce for object transformation
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: handleIdReplacement(key, value),
    }),
    {}
  );
};

export const duplicateElementDeep = <T extends BaseElement = BaseElement>(
  id: string,
  elements: T[],
  recursiveField: keyof T
): T[] => {
  return elements.reduce((mappedElements: T[], element) => {
    const childElements = element[recursiveField] as T[];
    if (element.id === id) {
      const clonedElement = replaceIdsRecursively(element);
      mappedElements.push(element, clonedElement);
      return mappedElements;
    }
    if (childElements.length) {
      mappedElements.push({
        ...element,
        [recursiveField]: duplicateElementDeep(
          id,
          childElements,
          recursiveField
        ),
      });
    } else {
      mappedElements.push(element);
    }
    return mappedElements;
  }, []);
};

export function flattenElements<T extends BaseElement = BaseElement>(
  items: T[],
  recursiveField: keyof T,
  parentId: string | null = null,
  depth = 0,
  ancestorIds: Array<string | number> = []
): any[] {
  if (!items?.length) {
    return [];
  }
  return items.reduce<any[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index, ancestorIds },
      ...flattenElements(
        (item[recursiveField] as T[]) || [],
        recursiveField,
        item.id as any,
        depth + 1,
        [...ancestorIds, item.id]
      ),
    ];
  }, []);
}
