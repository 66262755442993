import type { UploadsDto as MediaDto } from "../../dtos";
import { lazy, Suspense } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";

export interface LightboxGalleryProps {
  images: MediaDto[];
  currentIndex?: number;
  onClose: () => void;
  isMobile?: boolean;
}

const LightboxGallery = lazy(
  () => import(/* webpackChunkName: "LightboxGallery" */ "./LighboxGallery")
);

export function LazyLightboxGallery(props: LightboxGalleryProps) {
  const isMobile = useIsMobile();

  if (props.currentIndex === undefined || props.currentIndex < 0) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      {props.isMobile ? (
        <LightboxGallery {...props} />
      ) : (
        <LightboxGallery {...props} />
      )}
    </Suspense>
  );
}
