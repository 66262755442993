import React, { useEffect } from "react";
import { WidgetDto } from "../types";
import { FormSchemaForm } from "../../components/FormSchemaForm";
import { API } from "../../client/API";
import { useFormSchema } from "../hooks/useFormSchema";
import useToggle from "../../hooks/useToggle";
import { ProtectedComponent } from "../../components/ProtectedComponent/ProtectedComponent";
import { USER_ROLES } from "../../dtos";
import { Box, Paper } from "@mui/material";
import { ToggleSwitch } from "../../components/ToggleSwitch/ToggleSwitch";
import MarkdownComponent from "markdown-to-jsx";

export const FormPlugin: React.FC<
  WidgetDto & { setElementRef: (el: HTMLDivElement) => void }
> = ({ data = {}, setElementRef, initialData }) => {
  const { formId } = data;
  const [isDebug, toggleIsDebug] = useToggle(false);
  const [isPreloaded, toggleIsPreloaded] = useToggle(false);
  const [estimate, setEstimate] = React.useState<any>({});
  const { formSchema, isLoading, fetchFormSchema } = useFormSchema(
    formId,
    !initialData
  );
  const [initialValues, setInitialValues] = React.useState<any>({});

  useEffect(() => {
    if (!initialData || initialData?._id !== formId) {
      fetchFormSchema && fetchFormSchema();
    }
  }, [initialData, formId]);

  useEffect(() => {
    if (isPreloaded) {
      API.get("/estimates/672e5795f425f37fe81b3e74").then(setInitialValues);
    } else {
      setInitialValues({});
    }
  }, [isPreloaded]);

  const form = formSchema || initialData;
  const isEstimatesForm = form?.formSlug === "quote-request";

  const renderAiPrompt = (value: string) => {
    return value
      .split("\n")
      .map((line, index) => (
        <p key={`p-${index}-${line.slice(0, 3)}`}>{line}</p>
      ));
  };

  return (
    <div ref={setElementRef} className={data.className}>
      <ProtectedComponent
        isLoginEnabled={false}
        isRegisterEnabled={false}
        roles={[USER_ROLES.ADMIN]}
      >
        <Box textAlign="right">
          <ToggleSwitch isChecked={isPreloaded} onChange={toggleIsPreloaded}>
            {isPreloaded ? "Reset data" : "Preload Data"}
          </ToggleSwitch>
          <ToggleSwitch isChecked={isDebug} onChange={toggleIsDebug}>
            Debug info
          </ToggleSwitch>
        </Box>
      </ProtectedComponent>

      {form && (
        <FormSchemaForm
          isReinitializeEnabled
          form={form}
          isDefaultSubmit
          initialValues={initialValues}
          isDebug={isDebug}
          onSubmitSuccess={(values) => {
            setEstimate(values);
          }}
        />
      )}

      {isEstimatesForm && !!estimate?._id && !!estimate?.aiPrompt && (
        <Box component={Paper} p={2} variant="outlined">
          {renderAiPrompt(estimate.aiPrompt)}
        </Box>
      )}
      {!!estimate?.chatGptResponse && (
        <Box component={Paper} p={2} variant="outlined">
          <MarkdownComponent>{estimate.chatGptResponse}</MarkdownComponent>
        </Box>
      )}
    </div>
  );
};
