import React, { useEffect, useState } from "react";
import { useFormSchema } from "../hooks/useFormSchema";
import { FormSchemaForm } from "../../components/FormSchemaForm";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useUser } from "../../providers/UserProvider";

type Props = {
  formSlug: string;
  isProtected: boolean;
  initialValues?: any;
  handleSuccess?: (e?: CustomEvent<any>) => void;
};

export const ProtectedFormDialog: React.FC<Props> = ({
  formSlug,
  isProtected,
  initialValues = {},
  handleSuccess,
}) => {
  const { formSchema, isLoading } = useFormSchema(formSlug);
  const { formSchema: loginForm, isLoading: loginLoading } =
    useFormSchema("login-form");
  const { formSchema: registerForm, isLoading: registerLoading } =
    useFormSchema("register-form");
  const [view, setView] = useState<"login" | "register">("login");
  const { user } = useUser();

  useEffect(() => {
    if (!handleSuccess || !formSchema?.eventName) {
      return;
    }

    window.addEventListener(
      formSchema.eventName,
      handleSuccess as EventListener,
      false
    );

    return () => {
      window.removeEventListener(
        formSchema.eventName,
        handleSuccess as EventListener,
        false
      );
    };
  }, [handleSuccess, formSchema?.eventName]);

  const renderLoginView = () => {
    return (
      <Box p={2}>
        <Typography>
          You must be logged in to access this area. If you do not have an
          account create one here:
          <Button onClick={() => setView("register")}>Register account</Button>
        </Typography>
        <Box mx={-1} my={2}>
          {loginLoading ? (
            <CircularProgress />
          ) : (
            loginForm && (
              <FormSchemaForm form={loginForm} isDefaultSubmit isFieldsOnly />
            )
          )}
        </Box>
      </Box>
    );
  };

  const renderRegisterView = () => {
    return (
      <Box p={2}>
        <Typography>
          You must be logged in to access this area. If you already have an
          account click here:
          <Button onClick={() => setView("login")}>Login</Button>
        </Typography>
        <Box mx={-1} my={2}>
          {registerLoading ? (
            <CircularProgress />
          ) : (
            registerForm && (
              <FormSchemaForm
                form={registerForm}
                isDefaultSubmit
                isFieldsOnly
              />
            )
          )}
        </Box>
      </Box>
    );
  };

  if (isProtected && !user) {
    return view === "login" ? renderLoginView() : renderRegisterView();
  }

  return isLoading ? (
    <CircularProgress />
  ) : formSchema ? (
    formSchema && (
      <FormSchemaForm
        form={formSchema}
        initialValues={initialValues}
        isDefaultSubmit
        isFieldsOnly
      />
    )
  ) : null;
};
