import React from "react";
import { loadableReady } from "@loadable/component";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserProvider } from "./providers/UserProvider";
import { AppDataProvider } from "./providers/AppDataProvider";
import { CacheProvider } from "@emotion/react";
import { createEmotionCache } from "./utils/createEmotionCache";

const getWindowValue = (key: string) => {
  return window[key as any];
};

loadableReady().then(() => {
  hydrateRoot(
    document.getElementById("root") as HTMLElement,
    <CacheProvider value={createEmotionCache()}>
      <UserProvider user={getWindowValue("LOGGED_IN_USER")}>
        <AppDataProvider appData={getWindowValue("APP_DATA")}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppDataProvider>
      </UserProvider>
    </CacheProvider>
  );
  document.body.classList.remove("body-hidden");
});

declare const module: NodeModule & {
  hot?: {
    accept: () => void;
  };
};

if (module.hot) {
  module.hot.accept();
}
