import { Close } from "@mui/icons-material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {
  Dialog as MuiDialog,
  DialogProps,
  BoxProps,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import clsx from "clsx";
import { createContext, forwardRef, ForwardedRef, useContext } from "react";

const DialogContext = createContext<CustomDialogProps["onClose"]>(() => {});

type CustomDialogProps = {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
};

export function Dialog({
  isOpen,
  children,
  title,
  onClose,
  fullWidth = true,
  maxWidth = "sm",
  ...dialogProps
}: Partial<DialogProps> & CustomDialogProps) {
  return (
    <DialogContext.Provider value={onClose}>
      <MuiDialog
        {...dialogProps}
        open={isOpen}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        sx={{ zIndex: 9999 }}
        PaperProps={{
          sx: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundImage: "none",
            margin: dialogProps.scroll === "body" ? "32px auto" : undefined,
          },
        }}
      >
        {title && <Dialog.Header>{title}</Dialog.Header>}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          flex={1}
          className="dialog-main"
        >
          {children}
        </Box>
      </MuiDialog>
    </DialogContext.Provider>
  );
}

type DialogHeaderProps = {
  isBackButton?: boolean;
} & BoxProps;

Dialog.Header = ({
  className,
  children,
  isBackButton,
  ...boxProps
}: DialogHeaderProps) => {
  const onClose = useContext(DialogContext);
  return (
    <Box
      py={1}
      px={2}
      display="flex"
      alignItems="center"
      className={clsx("dialog-header", className)}
      justifyContent={isBackButton ? undefined : "space-between"}
      {...boxProps}
    >
      {isBackButton && (
        <IconButton onClick={onClose}>
          <ArrowBack />
        </IconButton>
      )}
      <Box flex={1} component="b">
        {children}
      </Box>
      {!isBackButton && (
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

Dialog.Content = forwardRef(
  (
    { className, children, ...boxProps }: BoxProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Box
        ref={ref}
        className={className}
        flex={1}
        py={1}
        px={2}
        sx={{
          overflowY: "auto",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "#434343",
        }}
        {...boxProps}
      >
        {children}
      </Box>
    );
  }
);

type DialogFooterProps = {
  isCancelHidden?: boolean;
} & BoxProps;

Dialog.Footer = ({
  className,
  children,
  isCancelHidden,
  ...boxProps
}: DialogFooterProps) => {
  const onClose = useContext(DialogContext);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      py={1}
      px={2}
      gap={1}
      {...boxProps}
      className={clsx("dialog-footer", className)}
    >
      {!isCancelHidden && (
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
      )}
      {children}
    </Box>
  );
};
