import { ChangeEvent, useState, FC } from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { ErrorOutline } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import { API } from "../../client/API";

const getFormData = (files: FileList): FormData => {
  const formData = new FormData();

  Array.from(files).forEach((file, idx) => {
    formData.append("images", file as any, file.name);
  });

  return formData;
};

type Props = {
  userId: string;
  field: "coverPhoto" | "profilePicture";
} & BoxProps;

export const ProfileUploadButton: FC<Props> = ({
  userId,
  field,
  ...boxProps
}) => {
  const [status, setStatus] = useState<"loading" | "complete" | "error" | null>(
    null
  );

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const formData = getFormData(files as FileList);

    if (status !== null) return;
    setStatus("loading");

    API.uploadImages(`/user/upload/${field}/${userId}`, formData)
      .then((data) => {
        window.dispatchEvent(new CustomEvent("USER_UPDATED", { detail: data }));
        setStatus("complete");
      })
      .catch((err) => {
        console.error(err);
        setStatus("error");
      });

    setTimeout(() => {
      setStatus(null);
    }, 1500);
  };

  const renderIcon = () => {
    switch (status) {
      case "loading":
        return <CircularProgress />;
      case "complete":
        return <Check />;
      case "error":
        return <ErrorOutline />;
      default:
        return <AddAPhotoIcon />;
    }
  };

  return (
    <Box {...boxProps}>
      <Tooltip
        placement="top"
        arrow
        componentsProps={{
          popper: { sx: { zIndex: 4001, cursor: "pointer" } },
        }}
        title={
          field === "coverPhoto"
            ? "Upload cover photo"
            : "Upload profile picture"
        }
      >
        <IconButton
          component="label"
          sx={{ position: "relative", cursor: "pointer" }}
        >
          <Box
            component="input"
            title=""
            sx={{
              opacity: 0,
              left: 0,
              width: 2,
              height: 2,
              top: 0,
              position: "absolute",
            }}
            multiple={true}
            name="images"
            onChange={onChange}
            type="file"
          />
          {renderIcon()}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
